import React from 'react'
import styled from 'styled-components'

import { Container, Theme } from 'common/UI'
import { ColumnLayoutStoryblok } from 'common/types'

import { DynamicBlock } from './DynamicBlock'
import { Editable } from './Editable'

type Props = {
  block: ColumnLayoutStoryblok
}

export const ColumnLayout = ({ block, ...props }: Props): JSX.Element => {
  const { columns } = block
  const [leftColumn, rightColumn] = columns || []

  return (
    <ColumnsWrapper {...props}>
      {leftColumn ? (
        <Theme css={{ flex: 1 }} colorTheme={leftColumn?.theme || 'light'}>
          <Editable block={leftColumn}>
            <LeftColumn isSticky={leftColumn.is_sticky}>
              {leftColumn?.body.map((block) => (
                <DynamicBlock key={block._uid} block={block} />
              ))}
            </LeftColumn>
          </Editable>
        </Theme>
      ) : (
        <LeftColumn />
      )}
      {rightColumn ? (
        <Theme css={{ flex: 1 }} colorTheme={rightColumn?.theme || 'light'}>
          <Editable block={rightColumn}>
            <RightColumn isSticky={rightColumn.is_sticky}>
              {rightColumn?.body.map((block) => (
                <DynamicBlock key={block._uid} block={block} />
              ))}
            </RightColumn>
          </Editable>
        </Theme>
      ) : (
        <RightColumn />
      )}
    </ColumnsWrapper>
  )
}

const ColumnsWrapper = styled.section`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`

const LeftColumn = styled(Container)<{ isSticky?: boolean }>`
  ${({ theme }) => theme.media.lg} {
    flex: 1;
    max-width: 41rem;
    margin-right: 0;
    padding-right: 3.75rem;
    position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
    top: ${({ isSticky }) => (isSticky ? '0' : 'unset')};
  }
`

const RightColumn = styled(Container)<{ isSticky?: boolean }>`
  ${({ theme }) => theme.media.lg} {
    flex: 1;
    max-width: 41rem;
    margin-left: 0;
    padding-left: 3.75rem;
    position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
    top: ${({ isSticky }) => (isSticky ? '0' : 'unset')};
  }
`
